import { defineStore } from 'pinia'

// You can name the return value of `defineStore()` anything you want, 
// but it's best to use the name of the store and surround it with `use` 
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useProjectStore = defineStore('project', {
    state: () => ({
        projects: [{
            title: 'Ulster Computing Blogs',
            description: 'Social blogging platform that I created as my final year dissertation project, it uses NLP to tag posts by the topics being discussed in the post itself',
            image: 'projects/UUCB.png',
            repo: 'https://github.com/lewsley-r/UUCB/',
            stack: ['VueJS', 'PHP Laravel', 'Docker', 'Kubernetes', 'GCP', 'Nginx', 'Python', 'Postgres']

        },
        {
            title: '682 MicroBlogs',
            description: 'Social platform that I created as my part of a cloud computing project.' +
                ' It uses Github actions for pipeline deployment, various Azure cloud services such as Azure functions, Cloud functions and static deployments',
            image: 'projects/COM682-Blogs-logos_black.png',
            repo: 'https://github.com/lewsley-r/682-microblogs',
            stack: ['Azure', 'VueJS', 'Azure Cloud DBs']

        },
        {
            title: 'Premier League Fantasy Team Builder',
            description: 'Fantasy team builder that I created for a full stack development project, it uses Angular as a frontend SPA, with Python Flask as a backend API',
            image: 'projects/ftb-logos_black.png',
            repo: 'https://github.com/lewsley-r/fantasy_team_builder/',
            stack: ['Anugular', 'Flask', 'MongoDB', 'Python']           
        },
        {
            title: 'Weather App',
            description: 'A small basic weather app in Python leveraging and open API for weather information. User Interface created with Python TKinter library', 
            image: '',
            repo: 'https://github.com/lewsley-r/weather-app-python/',
            stack: ['Python']           
        },
        {
            title: 'rlewsley.is-a.dev',
            description: 'My personal portfolio website showcasing some of the personal projects I have worked during my career as a software engineer',
            image: '@/assets/new_logo.png',
            repo: 'https://github.com/lewsley-r/weather-app-python/',
            stack: ['VueJS']           
        },
        {
            title: 'Progress/ Goal tracker', 
            description: 'This application I designed during a professional learning journey to aid our team to track our progress in learning and understanding a new framework. It allowed for oversight and basic analysis of team progress by team lead',
            image: '', 
            repo: 'https://github.com/lewsley-r/progress-tracker/',
            stack: ['VueJS', 'PHP Laravel', 'Docker', 'Nginx', 'Postgres']           
        },
        ],

    }),

    getters: {

    },

    actions: {

    },
})
